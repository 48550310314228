<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item active"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">View</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Learning Goals</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">Outcomes</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Views></Views>
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs1">
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row m-0 p-0">    
                                    <div class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${filter.cycle}`" disabled>
                                    </div>
                                    <div class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select @change="setUnit()" v-model="filter.unit_id" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="" selected disabled>Select Unit</option>
                                            <option v-for="(item, index) in units.point" :key="index" :value="item.id">{{item.content}}</option>
                                        </select>
                                    </div>
                                    <div v-if="filter.unit_id !== ''" class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select @change="setType()" v-model="filter.type" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="3">Individual Assignment</option>
                                            <option value="4">Group Assignment</option>
                                        </select>
                                    </div>
                                    <div v-if="filter.unit_id !== ''" class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select v-model="filter.id" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="" selected disabled>Select Assignment</option>
                                            <option v-for="(item, index) in assignment" :key="index" :value="item.id">{{item.title}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <LearningGoals :id="filter.id"></LearningGoals>
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs2">
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row m-0 p-0">    
                                    <div class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${filter.cycle}`" disabled>
                                    </div>
                                    <div class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select @change="setUnit()" v-model="filter.unit_id" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="" selected disabled>Select Unit</option>
                                            <option v-for="(item, index) in units.point" :key="index" :value="item.id">{{item.content}}</option>
                                        </select>
                                    </div>
                                    <div v-if="filter.unit_id !== ''" class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select @change="setType()" v-model="filter.type" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="3">Individual Assignment</option>
                                            <option value="4">Group Assignment</option>
                                        </select>
                                    </div>
                                    <div v-if="filter.unit_id !== ''" class="form-group col-lg-6 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <select v-model="filter.id" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                            <option value="" selected disabled>Select Assignment</option>
                                            <option v-for="(item, index) in assignment" :key="index" :value="item.id">{{item.title}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Outcomes :id="filter.id"></Outcomes>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Views from './Sub/Views.vue'
import LearningGoals from './Sub/LearningGoals.vue'
import Outcomes from './Sub/Outcomes.vue'
export default {
    name: 'SkillsBehavior',
    components:{
        Views,
        LearningGoals,
        Outcomes,
    },
    data() {
        return {
            slug: this.$route.params.idCourse,
            cycles: [],
            filter:{
                cycle: '',
                unit_id: '',
                type: 3,
                id: ''
            },
            units: [],
            assignment: [],
        }
    },
    created(){
        this.getCycles()
    },
    methods: {
        async getCycles(){
            if(localStorage.getItem(`${this.slug}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.slug}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.slug}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.slug}-cycle`)) {
                this.filter.cycle = localStorage.getItem(`${this.slug}-cycle`)
            } else {
                this.filter.cycle = this.cycles[0].cycle
            }
            this.getUnit()
        },
        setCycle(){
            this.filter.unit_id = '';
            this.getUnit();
        },
        setUnit() {
            this.getAssigment()
        },
        setType() {
            this.filter.id = ''
            this.getAssigment()
        },
        async getUnit() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/syllabus?slug=${this.slug}&cycle=${this.filter.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.units = res.data.data
            })
        },
        async getAssigment() {
            if(this.filter.unit_id != '') {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/get-assignment?slug=${this.slug}&type=${this.filter.type}&unit_id=${this.filter.unit_id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.assignment = res.data.data
                })
            }
        }
    }
}
</script>