<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row m-0 p-0">    
                                    <div class="form-group col-12 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${cycle}`" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Report :cycle="cycle"></Report>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Report from './Sub/Report.vue'
export default {
    name: 'Attitudes',
    components:{
        Report
    },
    data(){
        return {
            slug: this.$route.params.idCourse,
            cycles: [],
            cycle: ''
        }
    },
    created(){
        this.getCycles()
    },
    methods: {
        async getCycles(){
            if(localStorage.getItem(`${this.slug}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.slug}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.slug}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.slug}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.slug}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
        },
    }
}
</script>