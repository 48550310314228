<template>
    <div class="row mb-3">    
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Evaluation </h2>
                    </div>
                </div>            
            </div> 
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <splide :options="slide">
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseEvaluation', params: {idCourse: paramsId}, hash: '#knowledge'}">
                                <div style="background-image: url(/images/bg-2.png); background-position: center; background-size: 45%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#knowledge', 'border-0': tab != '#knowledge'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Knowledge</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseEvaluation', params: {idCourse: paramsId}, hash: '#skills'}">
                                <div style="background-image: url(/images/bg-2.png); background-position: center; background-size: 45%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#skills', 'border-0': tab != '#skills'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Skills and Behavior</h2>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseEvaluation', params: {idCourse: paramsId}, hash: '#attitudes'}">
                                <div style="background-image: url(/images/bg-2.png); background-position: center; background-size: 45%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#attitudes', 'border-0': tab != '#attitudes'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Attitudes</h2>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{name: 'MyCourseEvaluation', params: {idCourse: paramsId}, hash: '#reward'}">
                                <div style="background-image: url(/images/bg-2.png); background-position: center; background-size: 45%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#reward', 'border-0': tab != '#reward'}" class="card p-4 p-md--5 pb-5 text-left shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h2 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Reward &amp; Recognition</h2>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                    </splide>
                </div>
            </div>
            <div class="row mb-3" v-if="isShow">
                <div class="col-lg-12">
                    <div class="row" v-if="tab == '#knowledge'">
                        <div class="col-lg-12">
                            <knowledge></knowledge>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#skills'">
                        <div class="col-lg-12">
                            <skills-behavior></skills-behavior>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#attitudes'">
                        <div class="col-lg-12">
                            <attitudes></attitudes>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#reward'">
                        <div class="col-lg-12">
                            <reward-recognition></reward-recognition>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
// import axios from 'axios';
import Knowledge from './Knowledge/Knowledge.vue'
import Attitudes from './Attitudes/Attitudes.vue'
import SkillsBehavior from './SkillsBehavior/SkillsBehavior.vue'
import RewardRecognition from './RewardRecognition/RewardRecognition.vue'

export default {
    name: `MyCourseEvaluation`,
    components: {
        Knowledge,
        Attitudes,
        SkillsBehavior,
        RewardRecognition
    },
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            isShow: true,
            tab: '',
            slide: {
                start: this.$route.hash == '#knowledge' ? 0: this.$route.hash == '#skills' ? 1 : this.$route.hash == '#attitudes' ? 2 : this.$route.hash == '#reward' ? 3 : 0,
                rewind : false,
                perPage : 2,
                gap : '2rem',
                autoplay : false,
                arrows : true,
                pagination : false,
                breakpoints: {
                    '800' : {
                        perPage: 2,
                        gap: '10rem'
                    },
                    '640' : {
                        perPage: 1,
                        gap: '4rem'
                    },
                    '480' : {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
        }
    },
    created() {
        
    },
    mounted() {
        if (this.$route.hash) {
            this.isShow = true
            this.tab = this.$route.hash
        }
    }
}
</script>