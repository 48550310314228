<template>
    <div class="row">
        <div class="col-12">
            <h1 class="fw-700 text-center mt-3 mb-5"><i class="fas fa-exclamation-circle text-danger mr-2"></i> 5 Rank Most Error</h1>
            <div v-if="!isLoad" class="table-responsive">
                <table class="table table-hover table-striped">
                    <tbody v-if="outcomes.length != 0">
                        <tr class="text-center" v-for="(item, n) in outcomes" :key="n">
                            <td align="center" valign="middle" class="py-5" width="10%">
                                <h1 class="font-lg fw-800">{{n + 1}}</h1>
                            </td>
                            <td align="center" valign="middle" class="py-5" width="60%" v-html="item.grammar"></td>
                            <td align="center" valign="middle" class="py-5" width="30%">
                                <h1 class="fw-700 text-black">{{item.score}}</h1>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="3" align="center" valign="middle" class="py-3">
                                <h3 class="fw-800">Data is empty</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="w-100 py-3 text-center">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['id'],
    data(){
        return {
            slug: this.$route.params.idCourse,
            isLoad: true,
            outcomes: []
        }
    },
    created(){
        this.getOutcomes()
    },
    methods:{
        async getOutcomes(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/skill-behavior/outcome?slug=${this.slug}&id=${this.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.isLoad = false
                    this.outcomes = res.data.data
                }
            })
        }
    },
    watch: {
        id(){
            if(this.id != ''){
                this.getOutcomes();
            }
        }
    }
}
</script>