<template>
    <div class="row">
        <div class="col-12">
            <h1 class="fw-700 text-center mt-3 mb-5"><i class="fas fa-bullseye text-success mr-2"></i> Learning Goals</h1>
            <div class="table-responsive" v-if="grammar.length != 0">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-danger">Language Ability</th>
                            <th>Question</th>
                            <th>Question</th>
                            <th>Question</th>
                            <th>Question</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in grammar" :key="i">
                            <td>{{item.point}}</td>
                            <td><a v-if="item.goq[0]" @click="showModal(item.goq[0])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[1]" @click="showModal(item.goq[1])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[2]" @click="showModal(item.goq[2])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[3]" @click="showModal(item.goq[3])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <div class="alert alert-primary text-center bg-current">
                    <h3 class="fw-600 text-white">Choose your assignment!</h3>
                </div>
            </div>
        </div>
        <modal name="detail-modal"
            :width="'90%'"
            :height="'auto'"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
                <div class="row align-item-center justify-content-center pb-3" v-if="current_quest != null">
                    <div class="col-11 card p-0 mb-3">
                        <div class="card-body text-center" v-if="current_quest.quest.quest_type == 1">
                            <video class="w-50 h-auto" controls>
                                <source :src=" current_quest.quest.path" type="video/mp4">
                            </video>
                        </div>
                        <div class="card-body" v-if="current_quest.quest.quest_type == 2">
                            <audio class="w-100" controls>
                                <source :src=" current_quest.quest.path" type="audio/mpeg">
                            </audio>
                        </div>
                        <div class="card-body ql-editor">
                            <div v-html="current_quest.quest.question"></div>
                        </div>
                        <div v-if="current_quest.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                            File Upload
                        </div>
                        <div v-if="current_quest.quest.submission_type == 2" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                            Essay
                        </div>
                        <div v-if="current_quest.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                            <table class="table m-0">
                                <tr v-for="(opt, j) in current_quest.quest.option" :key="j">
                                    <td width="5%" v-if="opt.id == current_quest.quest.answer" class="bg-success"></td>
                                    <td width="5%" v-else class="bg-grey"></td>
                                    <td>{{opt.option}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['id'],
    data(){
        return{
            slug: this.$route.params.idCourse,
            grammar: [],
            current_quest: null
        }
    },
    methods: {
        async getLearningGoal(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/get-grammar-point-assignment?slug=${this.slug}&id=${this.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.grammar = res.data.data
            })
        },
        closeModal(){
            this.$modal.hide('detail-modal')
        },
        showModal(item){
            this.current_quest = item
            this.$modal.show('detail-modal')
        },
        beforeClose() {
            this.current_quest = null
            this.getGrammar()
        },
    },
    watch: {
        id(){
            if(this.id != ''){
                this.getLearningGoal();
            }
        }
    }
}
</script>