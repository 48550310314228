<template>
    <div class="row">
        <div class="col-12">
            <h1 class="fw-700 text-center mt-3 mb-5"><i class="fas fa-chart-bar text-primary mr-2"></i> Skills and Behavior Result</h1>
        </div>
        <div class="col-12">
            <h5 class="ml-3">Result Of Your Skills and Behavior On Chart</h5>
            <apexchart height="250" width="100%" type="bar" :options="result.options" :series="result.series"></apexchart>
        </div>
        <div class="col-12">
            <h5 class="ml-3">All Cycles Result Of Your Skills and Behavior On Chart</h5>
            <apexchart height="250" width="100%" type="bar" :options="all_result.options" :series="all_result.series"></apexchart>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: `Views`,
    data(){
        return {
            slug: this.$route.params.idCourse,
            result: {
                options: {
                    chart: {
                        id: 'behavior-view-grade'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                    
                },
                series: [],
            },
            all_result: {
                options: {
                    chart: {
                        id: 'all-behavior-view-grade'
                    },
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                    
                },
                series: [],
            }
        }
    },
    created(){
        this.getResult();
        this.getAllResult();
    },
    methods: {
        async getResult() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/skill-behavior/grade-result?slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.result = res.data.data
            })
        },
        async getAllResult() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/skill-behavior/all-grade-result?slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.all_result = res.data.data
            })
        },
    }
}
</script>